<template>
  <v-dialog v-model="dialog" max-width="450">
    <v-card>
      <template v-if="isImportError">
        <v-card-title> エラー </v-card-title>

        <duplicate-items
          v-show="csvBlank.account_code.length > 0"
          message="ファイル内の勘定科目コードが空です。"
          :items="csvBlank.account_code"
          :format="formatAccountCode"
        />

        <duplicate-items
          v-show="csvBlank.sub_code.length > 0"
          message="ファイル内の補助科目コードが空です。"
          :items="csvBlank.sub_code"
          :format="formatSubCode"
        />

        <!-- コードと名称が不一致の場合のみ -->
        <duplicate-items
          v-show="csvDuplicate.account_code.length > 0"
          message="ファイル内の勘定科目コードが重複しています。"
          :items="csvDuplicate.account_code"
          :format="formatAccountCode"
        />

        <duplicate-items
          v-show="csvDuplicate.sub_code.length > 0"
          message="ファイル内の補助科目コードが重複しています。"
          :items="csvDuplicate.sub_code"
          :format="formatSubCode"
        />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="close">
            {{ $t('message.modal_close_btn') }}
          </v-btn>
        </v-card-actions>
      </template>

      <template v-else>
        <v-toolbar dark height="40" flat class="mb-3"> 確認 </v-toolbar>

        <duplicate-items
          v-show="dbDuplicate.account_code.length > 0"
          message="登録済みの勘定科目コードと重複しています。(重複したデータは上書きされます)"
          :items="dbDuplicate.account_code"
          :format="formatAccountCode"
        />

        <duplicate-items
          v-show="hasGetNextCode && csvBlank.account_code.length > 0"
          message="ファイル内の勘定科目コードが空です。(コードは登録時に自動で付与されます)"
          :items="csvBlank.account_code"
          :format="formatAccountCode"
        />

        <duplicate-items
          v-show="dbDuplicate.account_name.length > 0"
          message="登録済みの勘定科目名と重複しています。"
          :items="dbDuplicate.account_name"
          :format="formatAccountName"
        />

        <duplicate-items
          v-show="csvBlank.account_name.length > 0"
          message="ファイル内の勘定科目名が空です。"
          :items="csvBlank.account_name"
          :format="formatAccountName"
        />

        <duplicate-items
          v-show="dbDuplicate.sub_code.length > 0"
          message="登録済みの補助科目コードと重複しています。(重複したデータは上書きされます)"
          :items="dbDuplicate.sub_code"
          :format="formatSubCode"
        />

        <duplicate-items
          v-show="hasGetNextCode && csvBlank.sub_code.length > 0"
          message="ファイル内の補助科目コードが空です。(コードは登録時に自動で付与されます)"
          :items="csvBlank.sub_code"
          :format="formatSubCode"
        />

        <duplicate-items
          v-show="dbDuplicate.sub_name.length > 0"
          message="登録済みの補助科目名と重複しています。"
          :items="dbDuplicate.sub_name"
          :format="formatSubName"
        />

        <duplicate-items
          v-show="csvBlank.sub_name.length > 0"
          message="ファイル内の補助科目名が空です。"
          :items="csvBlank.sub_name"
          :format="formatSubName"
        />

        <duplicate-items
          v-show="csvDuplicate.sub_name.length > 0"
          message="ファイル内の補助科目名が重複しています。"
          :items="csvDuplicate.sub_name"
          :format="formatSubName"
        />

        <v-card-text class="px-4 py-2">
          ファイルを確認の上、よろしければインポートをクリックしてください。
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="close()">
            {{ $t('message.modal_cancel_btn') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="
              onClick()
              close()
            "
          >
            {{ $t('message.modal_import_btn') }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import DuplicateItems from '@/pages/master/@components/@shared/DuplicateItems/DuplicateItems'

export default {
  components: {
    DuplicateItems,
  },
  props: {
    hasGetNextCode: {
      type: Boolean,
      default: false,
    },
    csvDuplicate: {
      type: Object,
      required: true,
    },
    dbDuplicate: {
      type: Object,
      required: true,
    },
    csvBlank: {
      type: Object,
      required: true,
    },
    deleteNames: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      duplicatesKeys: [
        {
          key: 'code',
          name: '勘定科目コード',
          subName: '補助科目コード',
        },
        {
          key: 'name',
          name: '勘定科目名',
          subName: '補助科目名',
        },
      ],
      dialog: false,
    }
  },
  computed: {
    isImportError() {
      const blankError =
        !this.hasGetNextCode &&
        (this.csvBlank.account_code.length > 0 ||
          this.csvBlank.sub_code.length > 0)
      const duplicateError =
        !this.hasGetNextCode &&
        (this.csvDuplicate.account_code.length > 0 ||
          this.csvDuplicate.sub_code.length > 0)
      return blankError || duplicateError
    },
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    onClick() {
      this.$emit('onClick')
    },
    formatAccountName(item) {
      return item.account_name
    },
    formatAccountCode(item) {
      return item.account_code
    },
    formatSubName(item) {
      return (
        '[ ' +
        item.account_code +
        ' ' +
        item.account_name +
        ' ] ' +
        item.sub_name
      )
    },
    formatSubCode(item) {
      return (
        '[ ' +
        item.account_code +
        ' ' +
        item.account_name +
        ' ] ' +
        item.sub_code
      )
    },
  },
}
</script>
