<template>
  <div v-if="checkPrivilege('account_title:read')">
    <app-section-loader :status="loading_page" />
    <progress-dialog ref="progressDialog" />

    <dialog-update-sort ref="dialogUpdateSort" @onClick="updateSort('bulk')" />

    <dialog-confirm-add ref="dialogConfirmAdd" @onClick="addInstance()" />

    <dialog-confirm-import
      ref="dialogConfirmImport"
      :has-get-next-code="hasGetNextCode"
      :csv-duplicate="csvDuplicate"
      :db-duplicate="dbDuplicate"
      :csv-blank="csvBlank"
      :delete-names="deleteNames"
      @onClick="importCsv()"
    />

    <dialog-add
      ref="dialogAdd"
      :inputs="inputs"
      :label="definition.journal.account_title_name"
      @onClick="checkAdd()"
    >
      <template v-slot:additionalForm>
        <v-autocomplete
          v-model="inputs.taxId"
          :items="tax_list"
          :label="definition.tax_class"
          clearable
          dense
          outlined
          filled
          item-text="name"
          item-value="id"
        />
      </template>
    </dialog-add>

    <dialog-edit-sort-num
      ref="dialogEditAccountTitleSortNum"
      v-model="editingAccountTitleSortNum"
      @submit="saveAccountTitleSortNum()"
    />

    <dialog-edit-sort-num
      ref="dialogEditSubjectSortNum"
      v-model="editingSubjectSortNum"
      @submit="saveSubjectSortNum()"
    />

    <dialog-edit
      ref="dialogEdit"
      :inputs="inputs"
      :label="definition.journal.account_title_name"
      max-width="1200"
      @onClick="submitEditAccount()"
    >
      <template v-slot:form>
        <v-row no-gutters>
          <v-radio-group v-model="inputs.status" row>
            <v-radio color="blue" label="表示" :value="1" />
            <v-radio color="blue" label="非表示" :value="2" />
          </v-radio-group>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-text-field
              v-model="inputs.name"
              :label="definition.journal.account_title_name"
              dense
              outlined
              filled
              :rules="inputRules"
              class="mr-1"
              required
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="inputs.code"
              :label="definition.journal.account_title_code"
              dense
              outlined
              filled
              :rules="inputCodeRules"
              class="mx-1"
              required
            />
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              v-model="inputs.taxId"
              :items="tax_list"
              :label="definition.tax_class"
              dense
              outlined
              filled
              class="ml-1"
              clearable
              item-text="name"
              item-value="id"
            />
          </v-col>
        </v-row>
      </template>

      <template v-slot:additionalForm>
        <v-card outlined class="pa-3 mb-3">
          <v-menu
            offset-y
            top
            z-index="9999"
            transition="slide-y-transition"
            max-width="500"
            nudge-top="20"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" class="mb-5" v-on="on">
                <b>必須入力設定</b>
                <s-icon icon="feather-info" class="tw-pl-2" />
              </div>
            </template>
            <v-card class="sweeep-popover" max-width="500">
              <v-card-title v-text="'必須設定について'" />
              <v-card-text>
                仕訳登録画面で勘定科目を選択した時の各管理項目の入力ルールを指定できます。<br />
                「未払金を選択した場合は税区分を課税対象外にする」などの設定が可能になります。
                <div class="mt-2">
                  <s-chip> 会社設定に従う </s-chip>
                  システム設定＞会社情報の設定が適用されます（全社単位の設定）<br />会社情報設定は管理者権限者のみ変更が可能です。
                </div>
                <div class="mt-2">
                  <s-chip> 入力必須 </s-chip>
                  仕訳登録画面で勘定科目を選択した時に必須入力とします
                </div>
                <div class="mt-2">
                  <s-chip> 入力禁止 </s-chip>
                  仕訳登録画面で勘定科目を選択した時に入力ができなくなります
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
          <template v-if="displaySetting.subject">
            <v-row no-gutters>
              <v-col cols="3" class="pl-3 pt-2 fs-12">
                {{ definition.sub_account }}
              </v-col>
              <v-col cols="9" class="py-0">
                <v-radio-group
                  v-model="selected.settings_subaccount"
                  class="my-0"
                  row
                >
                  <v-radio label="会社設定に従う" :value="-1" />
                  <v-radio label="入力必須" :value="2" />
                  <v-radio label="入力禁止" :value="0" />
                  <v-radio label="任意" :value="1" />
                </v-radio-group>
              </v-col>
            </v-row>
          </template>

          <template v-if="displaySetting.department">
            <v-row no-gutters>
              <v-col cols="3" class="pl-3 pt-2 fs-12">
                {{ definition.department }}
              </v-col>
              <v-col cols="9" class="py-0">
                <v-radio-group
                  v-model="selected.settings_department"
                  class="my-0"
                  row
                >
                  <v-radio label="会社設定に従う" :value="-1" />
                  <v-radio label="入力必須" :value="2" />
                  <v-radio label="入力禁止" :value="0" />
                  <v-radio label="任意" :value="1" />
                </v-radio-group>
              </v-col>
            </v-row>
          </template>

          <template v-if="displaySetting.vendor">
            <v-row no-gutters>
              <v-col cols="3" class="pl-3 pt-2 fs-12">
                {{ definition.vendor }}
              </v-col>
              <v-col cols="9" class="py-0">
                <v-radio-group
                  v-model="selected.settings_vendor"
                  class="my-0"
                  row
                >
                  <v-radio label="会社設定に従う" :value="-1" />
                  <v-radio label="入力必須" :value="2" />
                  <v-radio label="入力禁止" :value="0" />
                  <v-radio label="任意" :value="1" />
                </v-radio-group>
              </v-col>
            </v-row>
          </template>

          <template v-if="displaySetting.project">
            <v-row no-gutters>
              <v-col cols="3" class="pl-3 pt-2 fs-12">
                {{ definition.project }}
              </v-col>
              <v-col cols="9" class="py-0">
                <v-radio-group
                  v-model="selected.settings_project"
                  row
                  class="my-0"
                >
                  <v-radio label="会社設定に従う" :value="-1" />
                  <v-radio label="入力必須" :value="2" />
                  <v-radio label="入力禁止" :value="0" />
                  <v-radio label="任意" :value="1" />
                </v-radio-group>
              </v-col>
            </v-row>
          </template>

          <template v-if="displaySetting.segment">
            <v-row no-gutters>
              <v-col cols="3" class="pl-3 pt-2 fs-12">
                {{ definition.segment }}
              </v-col>
              <v-col cols="9" class="py-0">
                <v-radio-group
                  v-model="selected.settings_segment"
                  class="my-0"
                  row
                >
                  <v-radio label="会社設定に従う" :value="-1" />
                  <v-radio label="入力必須" :value="2" />
                  <v-radio label="入力禁止" :value="0" />
                  <v-radio label="任意" :value="1" />
                </v-radio-group>
              </v-col>
            </v-row>
          </template>

          <template v-if="displaySetting.item">
            <v-row no-gutters>
              <v-col cols="3" class="pl-3 pt-2 fs-12">
                {{ definition.item }}
              </v-col>
              <v-col cols="9" class="py-0">
                <v-radio-group
                  v-model="selected.settings_item"
                  class="my-0"
                  row
                >
                  <v-radio label="会社設定に従う" :value="-1" />
                  <v-radio label="入力必須" :value="2" />
                  <v-radio label="入力禁止" :value="0" />
                  <v-radio label="任意" :value="1" />
                </v-radio-group>
              </v-col>
            </v-row>
          </template>

          <template v-if="displaySetting.tag">
            <v-row no-gutters>
              <v-col cols="3" class="pl-3 pt-2 fs-12">
                {{ definition.tag }}
              </v-col>
              <v-col cols="9" class="py-0">
                <v-radio-group v-model="selected.settings_tag" class="my-0" row>
                  <v-radio label="会社設定に従う" :value="-1" />
                  <v-radio label="入力必須" :value="2" />
                  <v-radio label="入力禁止" :value="0" />
                  <v-radio label="任意" :value="1" />
                </v-radio-group>
              </v-col>
            </v-row>
          </template>

          <template v-if="displaySetting.walletable">
            <v-row no-gutters>
              <v-col cols="3" class="pl-3 pt-2 fs-12">
                {{ definition.walletable }}
              </v-col>
              <v-col cols="9" class="py-0">
                <v-radio-group
                  v-model="selected.settings_walletable"
                  class="my-0"
                  row
                >
                  <v-radio label="会社設定に従う" :value="-1" />
                  <v-radio label="入力必須" :value="2" />
                  <v-radio label="入力禁止" :value="0" />
                  <v-radio label="任意" :value="1" />
                </v-radio-group>
              </v-col>
            </v-row>
          </template>

          <template v-if="displaySetting.tax">
            <v-row no-gutters>
              <v-col cols="3" class="pl-3 pt-2 fs-12">
                {{ definition.tax_class }}
              </v-col>
              <v-col cols="9" class="py-0">
                <v-radio-group v-model="selected.settings_tax" class="my-0" row>
                  <v-radio label="会社設定に従う" :value="-1" />
                  <v-radio label="入力必須" :value="2" />
                  <v-radio label="入力禁止" :value="0" />
                  <v-radio label="任意" :value="1" />
                </v-radio-group>
              </v-col>
            </v-row>
          </template>
        </v-card>
      </template>
      <template v-slot:additionalRightForm>
        <v-card outlined class="pa-3 mb-3">
          <div class="mb-5">
            <b>補助科目設定</b>
          </div>
          <template v-for="(subject, index) in selectedAccountSubjects">
            <v-row :key="index" no-gutters>
              <v-col cols="4">
                <v-text-field
                  v-model="subject.name"
                  :label="definition.journal.sub_account_name"
                  dense
                  outlined
                  filled
                  :rules="inputRules"
                  class="mr-1"
                  required
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="subject.code"
                  :label="definition.journal.sub_account_code"
                  dense
                  outlined
                  :rules="inputCodeRules"
                  filled
                  class="mx-1"
                  required
                />
              </v-col>
              <v-col cols="5">
                <v-radio-group
                  v-model="subject.status"
                  row
                  dense
                  hide-details
                  class="mt-1"
                >
                  <v-radio color="blue" label="表示" :value="1" />
                  <v-radio color="blue" label="非表示" :value="2" />
                  <v-btn
                    v-if="checkPrivilege('account_title:delete')"
                    small
                    rounded
                    color="default"
                    @click="deleteSuppSubject(index)"
                  >
                    {{ $t('message.table_delete_btn') }}
                  </v-btn>
                </v-radio-group>
              </v-col>
            </v-row>
          </template>
          <v-row class="ml-2">
            <div class="fw-bold my-3">
              補助科目を追加
              <v-btn icon x-small color="primary" @click="addSuppSubject()">
                <s-icon icon="feather-plus" color="current" size="xl" />
              </v-btn>
            </div>
          </v-row>
        </v-card>
      </template>
    </dialog-edit>

    <dialog-bulk-edit
      ref="dialogBulkEdit"
      :value="bulkEditInputs"
      @onClick="submitBulkEdit()"
    />

    <dialog-bulk-delete ref="dialogBulkDelete" @onClick="submitBulkDelete()" />

    <input-delete-dialog
      ref="dialogDelete"
      max-width="400"
      :title="$t('message.delete')"
      :message="$t('message.accounting_delete_account')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :delete-text="$t('message.table_delete_btn')"
      @submit="
        deleteAccount()
        $refs.dialogDelete.close()
      "
    />

    <v-card-title class="py-0 px-6">
      <v-btn
        v-if="checkPrivilege('account_title:create')"
        color="primary"
        dark
        @click.stop="
          $refs.dialogAdd.open()
          resetForm()
        "
      >
        <s-icon icon="feather-plus" size="lg" class="tw-mr-1" />
        {{ $t('message.add') }}
      </v-btn>

      <v-btn
        v-if="checkPrivilege('account_title:update')"
        class="ml-2"
        @click.stop="clickBulkButton('edit')"
      >
        {{ $t('message.bulk_edit') }}
      </v-btn>

      <v-btn
        v-if="checkPrivilege('account_title:delete')"
        class="ml-2"
        @click.stop="clickBulkButton('delete')"
      >
        {{ $t('message.bulk_delete') }}
      </v-btn>

      <v-btn
        v-if="checkPrivilege('account_title:create')"
        class="ml-2"
        @click.native.stop="importCsvBtn()"
      >
        {{ $t('message.accounting_import_account') }}
      </v-btn>
      <input
        id="accounting_import_csv"
        type="file"
        class="sweeep-import-csv-hidden"
        @change="readCsv($event)"
      />

      <v-btn
        v-if="checkPrivilege('account_title:create')"
        v-tooltip="'インポート用のデータサンプルを取得'"
        class="ml-2"
        text
        @click="exportMaster('account_title', 'sample')"
      >
        {{ $t('message.accounting_get_sample') }}
      </v-btn>

      <v-btn
        v-if="checkPrivilege('account_title:export')"
        class="ml-2"
        @click="exportMaster('account_title', 'all')"
      >
        {{ $t('message.master_export') }}
      </v-btn>

      <v-btn
        v-if="checkPrivilege('account_title:update')"
        v-tooltip="'表示順を一括で変更します'"
        class="ml-2"
        @click="$refs.dialogUpdateSort.open()"
      >
        <i class="ti-exchange-vertical mr-2" />
        {{ $t('message.save_sort_num') }}
      </v-btn>

      <v-btn
        v-show="displaySetting.subject && !showAllSubjects"
        v-tooltip="'補助科目の表示、非表示を切り替えます'"
        class="ml-2"
        @click="onClickShowAllSubjects"
      >
        <i class="ti-layout-accordion-list mr-2" />
        補助科目表示
      </v-btn>

      <v-btn
        v-show="displaySetting.subject && showAllSubjects"
        v-tooltip="'補助科目の表示、非表示を切り替えます'"
        class="ml-2"
        @click="onClickShowAllSubjects"
      >
        <i class="ti-view-list-alt mr-2" />
        補助科目非表示
      </v-btn>

      <v-spacer />
      <v-text-field
        v-model="search"
        placeholder="検索"
        filled
        dense
        rounded
        class="mt-5"
        clearable
        clear-icon="feather-x-circle"
      />
    </v-card-title>

    <v-data-table
      id="account-title-table"
      class="px-6"
      :headers="computedHeaders"
      :items="data"
      :search="search"
      item-key="id"
      fixed-header
      hide-default-header
      hide-default-footer
      height="calc(100vh - 215px)"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @pagination="onPagenation"
      @page-count="pageCount = $event"
      @current-items="getfilteredData"
    >
      <template #header="{ props: { headers } }">
        <thead>
          <tr>
            <template v-for="headeritem in headers">
              <th v-if="check_display_setting(headeritem)">
                <div v-if="headeritem.value == 'select'">
                  <select-all-header-cell
                    :items="data"
                    :tooltip="'全選択指定方法を表示（勘定科目のみ選択可能です。補助科目は選択されません。）'"
                    compute-value-with-items
                    @click:item="onClickSelectAll($event.value, $event.message)"
                  />
                </div>
                <div v-if="headeritem.definition">
                  {{ headeritem.text + definition[headeritem.definition] }}
                </div>
                <div v-if="!headeritem.definition">
                  {{ headeritem.text }}
                </div>
              </th>
            </template>
          </tr>
        </thead>
      </template>

      <template v-if="!loading_page" #no-data>
        <v-alert :value="true" class="mt-3" color="yellow" icon="warning">
          {{ $t('message.noResult') }}
        </v-alert>
      </template>

      <template #body="{ items }">
        <tbody>
          <template v-for="(item, index) in items">
            <!-- account title row -->
            <tr :key="index">
              <td>
                <v-simple-checkbox
                  :value="item.selected"
                  @input="toggleOne(item)"
                />
              </td>
              <td v-if="displaySetting.subject">
                <v-btn text icon @click="showSuppSubjects(item)">
                  <s-icon
                    :icon="
                      item.showsubjects == 0 ? 'feather-plus' : 'feather-minus'
                    "
                    class="pa-3"
                  />
                </v-btn>
              </td>
              <td
                v-if="checkPrivilege('account_title:update')"
                v-tooltip="$t('message.edit_sort_num')"
                class="sort_num"
                width="80"
                @click="openAccountTitleSortNum(item)"
              >
                {{ item.sort_num }}
              </td>
              <td v-else width="80">
                {{ item.sort_num }}
              </td>
              <td width="100">
                {{ item.code }}
              </td>
              <td width="100">
                {{ item.name }}
              </td>
              <td v-if="displaySetting.subject" width="100" />
              <td v-if="displaySetting.tax" width="90">
                {{ item.tax_name }}
              </td>
              <td v-if="displaySetting.subject">
                {{ getDisplaySettingName(item.settings_subaccount) }}
              </td>
              <td v-if="displaySetting.department">
                {{ getDisplaySettingName(item.settings_department) }}
              </td>
              <td v-if="displaySetting.vendor">
                {{ getDisplaySettingName(item.settings_vendor) }}
              </td>
              <td v-if="displaySetting.project">
                {{ getDisplaySettingName(item.settings_project) }}
              </td>
              <td v-if="displaySetting.segment">
                {{ getDisplaySettingName(item.settings_segment) }}
              </td>
              <td v-if="displaySetting.item">
                {{ getDisplaySettingName(item.settings_item) }}
              </td>
              <td v-if="displaySetting.tag">
                {{ getDisplaySettingName(item.settings_tag) }}
              </td>
              <td v-if="displaySetting.walletable">
                {{ getDisplaySettingName(item.settings_walletable) }}
              </td>
              <td v-if="displaySetting.tax">
                {{ getDisplaySettingName(item.settings_tax) }}
              </td>

              <td width="100" align="center">
                <s-chip v-if="item.status == 1" color="blue-500" class="px-4">
                  表示
                </s-chip>
                <s-chip v-if="item.status == 2" color="red-500" class="px-3">
                  非表示
                </s-chip>
              </td>

              <td v-if="hasEditCol" width="140">
                <div class="d-flex justify-space-around">
                  <v-btn
                    v-if="checkPrivilege('account_title:update')"
                    small
                    rounded
                    color="primary"
                    @click.native.stop="$refs.dialogEdit.open()"
                    @click="selectAccount(item)"
                  >
                    {{ $t('message.table_edit_btn') }}
                  </v-btn>
                  <v-btn
                    v-if="checkPrivilege('account_title:delete')"
                    small
                    rounded
                    color=""
                    @click.native.stop="$refs.dialogDelete.open()"
                    @click="selectAccount(item)"
                  >
                    {{ $t('message.table_delete_btn') }}
                  </v-btn>
                </div>
              </td>
            </tr>

            <!-- subjects rows -->
            <template v-if="item.showsubjects == 1">
              <tr
                v-for="(subItem, subIndex) in item.subjects"
                :key="`subjects_${index}_${subIndex}`"
              >
                <td />
                <td class="text-center">
                  <span class="sw-badge-success">補助</span>
                </td>
                <td
                  v-tooltip="$t('message.edit_sort_num')"
                  class="sort_num"
                  @click="openSubjectSortNum(subItem)"
                >
                  {{ subItem.sort_num }}
                </td>
                <td>
                  <div>
                    {{ subItem.code }}
                  </div>
                </td>
                <td />
                <td>
                  <div>
                    {{ subItem.name }}
                  </div>
                </td>
                <td v-if="displaySetting.tax" />
                <td v-if="displaySetting.subject" />
                <td v-if="displaySetting.department" />
                <td v-if="displaySetting.vendor" />
                <td v-if="displaySetting.project" />
                <td v-if="displaySetting.segment" />
                <td v-if="displaySetting.item" />
                <td v-if="displaySetting.tag" />
                <td v-if="displaySetting.walletable" />
                <td v-if="displaySetting.tax" />

                <td width="100" align="center">
                  <div>
                    <s-chip
                      v-if="subItem.status == 1"
                      color="blue-500"
                      class="px-4"
                    >
                      表示
                    </s-chip>
                    <s-chip
                      v-if="subItem.status == 2"
                      color="red-500"
                      class="px-3"
                    >
                      非表示
                    </s-chip>
                  </div>
                </td>
                <td />
              </tr>
            </template>
          </template>
        </tbody>
      </template>
    </v-data-table>

    <v-toolbar
      flat
      height="60"
      class="mt-3 pl-2"
      style="width: 100%; border-top: 1px solid #ccc"
    >
      <v-col cols="6" class="pa-0">
        <v-card flat tile class="d-flex align-content-center flex-wrap">
          <v-card flat class="align-self-center mr-3">
            <div class="fs-10 mb-1">表示件数</div>
            <span>
              <v-autocomplete
                v-model="itemsPerPage"
                :items="itemsPerPageOptions"
                dense
                filled
                hide-details
                item-text="text"
                item-value="value"
                class="more-dense"
                style="width: 100px"
                :clearable="false"
                @change="$emit('change:items-per-page', itemsPerPage)"
              />
            </span>
          </v-card>
          <v-card flat class="align-self-center mr-3">
            <div class="fs-10 mb-1">ページ</div>
            <span>
              <v-autocomplete
                v-model="page"
                :items="pageCountItems"
                dense
                filled
                hide-details
                class="more-dense"
                style="width: 100px"
                :clearable="false"
              />
            </span>
          </v-card>

          <v-card flat class="align-self-center">
            <div class="fs-10 mb-1">表示中</div>
            <v-chip small label class="px-4 py-3">
              {{ pageInfo.start }} - {{ pageInfo.end }} / {{ itemsLength }}
            </v-chip>
          </v-card>
        </v-card>
      </v-col>

      <v-spacer />
      <v-col cols="6" class="text-right pa-0">
        <v-pagination
          v-model="page"
          circle
          light
          style="width: auto"
          :length="pageCount"
        />
      </v-col>
    </v-toolbar>
  </div>
</template>

<script>
import Axios from 'axios'
import { scrollTableToTop } from 'Helpers/table/scroll'
import { getCurrentAppLayout, deepCopy } from 'Helpers/helpers'
import { setDisplaySetting } from 'Helpers/journal/index'
import Snackbar from 'Helpers/snackbar/index'
import DialogUpdateSort from '@/pages/master/@components/@shared/DialogUpdateSort/DialogUpdateSort'
import DialogConfirmAdd from '@/pages/master/@components/@shared/DialogConfirmAdd/DialogConfirmAdd'
import DialogAdd from '@/pages/master/@components/@shared/DialogAdd/DialogAdd'
import DialogEdit from '@/pages/master/AccountTitle/@components/DialogEditAccount/DialogEditAccount'
import DialogEditSortNum from '@/pages/master/@components/@shared/DialogEditSortNum/DialogEditSortNum'
import DialogBulkEdit from '@/pages/master/@components/@shared/DialogBulkEdit/DialogBulkEdit'
import DialogBulkDelete from '@/pages/master/@components/@shared/DialogBulkDelete/DialogBulkDelete'
import DialogConfirmImport from '@/pages/master/AccountTitle/@components/DialogConfirmImportAccount/DialogConfirmImportAccount'
import { toastErrorOnCheckImport } from '@/pages/master/@modules/mutators/toast'
import {
  initInputsObj,
  updateTableDataStatus,
  deleteTableData,
} from 'Helpers/master/index'
import SelectAllHeaderCell from 'Components/Table/SelectAllHeaderCell'

export default {
  components: {
    DialogUpdateSort,
    DialogConfirmAdd,
    DialogConfirmImport,
    DialogAdd,
    DialogEdit,
    DialogEditSortNum,
    DialogBulkEdit,
    DialogBulkDelete,
    SelectAllHeaderCell,
  },
  props: {
    definition: Object,
    getJournalItems: Function,
    getMaster: Function,
    exportMaster: Function,
    sortMaster: Function,
    organization: null,
  },
  data() {
    var t = this
    return {
      data: [],
      filteredData: [],
      tax_list: [],

      name: '勘定科目',
      import_data: {},

      dbExist: true,
      hasGetNextCode: false,
      csvDuplicate: {
        name: [],
        code: [],
      },
      dbDuplicate: {
        name: [],
        code: [],
      },
      csvBlank: {
        name: [],
        code: [],
      },
      deleteNames: [],
      fileReaded: 0,
      loading_page: false,

      page: 1,
      itemsPerPage: 50,
      pageCount: 0,
      itemsLength: 0,
      itemsPerPageOptions: [
        { value: 50, text: '50件' },
        { value: 100, text: '100件' },
        { value: 200, text: '200件' },
      ],
      pageInfo: {
        start: 0,
        end: 0,
      },

      statusOptions: [
        { value: 1, text: '表示' },
        { value: 2, text: '非表示' },
      ],

      displaySettingItems: [
        { val: -1, name: '会社設定に従う' },
        { val: 0, name: '入力禁止' },
        { val: 1, name: '入力任意' },
        { val: 2, name: '入力必須' },
      ],
      displaySetting: {
        sort_num: true,
        code: true,
        account_title: true,
        subject: true,
        tax: true,
        department: true,
        project: true,
        segment: true,
        vendor: true,
        item: true,
        tag: true,
        walletable: true,
      },

      expanded: {},
      valid_add_supplementary: false,
      valid_add_supplementary_code: false,

      search: '',
      headers: [
        { text: '', value: 'select', display: true },
        { text: '', value: 'data-table-expand', name: '', display: 'subject' },
        {
          text: t.$t('message.sort_num'),
          value: 'sort_num',
          display: true,
          sortable: true,
        },
        {
          text: t.$t('message.code'),
          value: 'code',
          display: true,
          sortable: true,
        },
        {
          text: t.definition.journal.account_title_name,
          value: 'name',
          display: true,
          sortable: true,
        },
        {
          text: t.definition.journal.sub_account_name,
          value: 'subject',
          name: 'subject',
          display: 'subject',
          sortable: true,
        },
        {
          text: t.definition.journal.tax_class_name,
          value: 'tax_name',
          name: 'tax',
          display: 'tax',
          sortable: true,
        },
        {
          text: '入力設定',
          definition: 'sub_account',
          value: 'settings_subaccount',
          name: 'subject',
          display: 'subject',
        },
        {
          text: '入力設定',
          definition: 'department',
          value: 'settings_department',
          name: 'department',
          display: 'department',
        },
        {
          text: '入力設定',
          definition: 'vendor',
          value: 'settings_vendor',
          name: 'vendor',
          display: 'vendor',
        },
        {
          text: '入力設定',
          definition: 'project',
          value: 'settings_project',
          name: 'project',
          display: 'project',
        },
        {
          text: '入力設定',
          definition: 'segment',
          value: 'settings_segment',
          name: 'segment',
          display: 'segment',
        },
        {
          text: '入力設定',
          definition: 'item',
          value: 'settings_item',
          name: 'item',
          display: 'item',
        },
        {
          text: '入力設定',
          definition: 'tag',
          value: 'settings_tag',
          name: 'tag',
          display: 'tag',
        },
        {
          text: '入力設定',
          definition: 'walletable',
          value: 'settings_walletable',
          name: 'walletable',
          display: 'walletable',
        },
        {
          text: '入力設定',
          definition: 'tax_class',
          value: 'settings_tax',
          name: 'tax',
          display: 'tax',
        },
        { text: '表示', value: 'status', display: true },
        { text: '', value: 'action', sortable: false, display: true },
      ],
      selected: {},
      input_tax_rate: '',

      inputs: {
        code: '',
        name: '',
        taxId: '',
        status: 1,
        gensenzei_account: false,
      },
      bulkEditInputs: { status: 1 },
      inputCodeRules: [
        (v) => !!v || t.$t('message.form_input_required'),
        (v) => v.length <= 255 || '255' + t.$t('message.character_max_length'),
        (v) => this.checkDuplicateCode(v) || 'コードが重複しています',
        (v) =>
          !v ||
          v.replace(/^[ 　]*/, '').length !== 0 ||
          '※スペースのみの登録はできません',
      ],
      inputRules: [
        (v) => !!v || t.$t('message.form_input_required'),
        (v) =>
          (v && v.replace(/\s/g, '').length >= 1) ||
          t.$t('message.form_input_required'),
        (v) => v.length <= 255 || '255' + t.$t('message.character_max_length'),
      ],
      inputSortNumRules: [
        (v) => !!v || t.$t('message.form_input_required'),
        (v) => (!!v && v > 0) || t.$t('message.form_input_required_above_zero'),
      ],
      selectedAccount: [],
      selectedAccountSubjects: [],
      selectedIds: [],
      selected_account_index: '',
      selectedSupplementary: [],
      selected_supplementary_propIndex: '',
      selected_supplementary_index: '',
      pagination: {},
      editingAccountTitleSortNum: '',
      editingSubjectSortNum: '',
      showAllSubjects: true,
    }
  },
  computed: {
    check_display_setting: function () {
      return function (obj) {
        try {
          if (obj.display === true) {
            return true
          } else if (obj.display === false) {
            return false
          } else {
            return this.displaySetting[obj.display]
          }
        } catch (e) {
          console.log(e)
          return false
        }
      }
    },
    pageCountItems() {
      return Array.from({ length: this.pageCount }, (v, k) => k + 1)
    },
    hasEditCol: function () {
      const resource = 'account_title'
      return (
        this.checkPrivilege(`${resource}:create`) ||
        this.checkPrivilege(`${resource}:update`) ||
        this.checkPrivilege(`${resource}:delete`)
      )
    },
    computedHeaders: function () {
      if (this.hasEditCol) {
        return this.headers
      } else {
        return this.headers.slice(0, this.headers.length - 1)
      }
    },
  },
  watch: {
    fileReaded: function (val) {
      if (val > 0) {
        this.checkCsv()
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('getUsers')
  },
  mounted() {
    this.getData()
    this.getTaxList()
    this.getDisplaySetting()
  },
  methods: {
    getData() {
      this.loading_page = true
      this.getMaster('account_title').then((data) => {
        this.data = data
        this.loading_page = false
      })
    },
    getTaxList() {
      this.getMaster('tax_class').then((data) => {
        this.tax_list = data
      })
    },
    updateSort(mode) {
      // mode : bulk or one
      try {
        const data = {
          name: 'account_title',
          mode: mode,
          items: this.data,
          order_value: this.$refs.dialogUpdateSort.form.order_value,
          order_type: this.$refs.dialogUpdateSort.form.order_type,
        }
        this.sortMaster(data).then((result) => {
          if (result.status === 'success') {
            this.data = result.items
            if (mode == 'bulk') {
              this.$refs.dialogUpdateSort.close()
            }
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    getDisplaySettingName(val) {
      try {
        return this.displaySettingItems.find((obj) => {
          return obj.val == val
        }).name
      } catch (e) {
        console.log(e)
      }
    },
    checkHeader(val) {
      if (val !== undefined) {
        return true
      }
      return false
    },
    importCsvBtn() {
      $('#accounting_import_csv').click()
    },
    setBlanktoFileInput() {
      document.querySelector('#accounting_import_csv').value = ''
    },
    readCsv(event) {
      try {
        var t = this
        var f = event.target.files[0]
        var reader = new FileReader()
        reader.onload = (function (f) {
          return function (e) {
            var data = {
              base64: e.target.result,
            }
            t.$store
              .dispatch('importCSV_Accounting', { data })
              .then((response) => {
                let result_array = response
                t.import_data = {
                  name: 'account_title',
                  items: result_array,
                  organization_id: t.$store.getters.getOrganizationID,
                }
                t.fileReaded += 1
              })
          }
        })(f)
        t.setBlanktoFileInput()
        reader.readAsDataURL(f)
      } catch (e) {
        console.log(e)
      }
    },
    checkGetNextCode() {
      const data = this.import_data
      this.hasGetNextCode = false
      let subNameCount = 0
      for (const item of data.items) {
        if (item.sub_name) {
          subNameCount += 1
        }
      }
      let subNameBlankCount = 0
      for (const item of this.csvBlank.sub_code) {
        subNameBlankCount += item.count
      }
      if (this.csvBlank.account_code[0]) {
        this.hasGetNextCode =
          !this.dbExist &&
          this.csvBlank.account_code[0].count === data.items.length &&
          subNameBlankCount === subNameCount
      }
    },
    checkCsv() {
      const data = this.import_data

      this.$refs.progressDialog.open(
        this.$t('message.progress_check_import_naster')
      )

      this.$store
        .dispatch('checkImportMasterData', data)
        .then((response) => {
          this.$refs.progressDialog.close()

          this.dbExist = response.data.db_exist
          this.csvDuplicate = response.data.csv_duplicate
          this.dbDuplicate = response.data.db_duplicate
          this.csvBlank = response.data.csv_blank
          this.checkGetNextCode()

          if (
            this.csvDuplicate.count > 0 ||
            this.dbDuplicate.count > 0 ||
            this.csvBlank.count > 0
          ) {
            this.$refs.dialogConfirmImport.open()
          } else {
            this.importCsv()
          }
        })
        .catch((err) => {
          this.$refs.progressDialog.close()
          toastErrorOnCheckImport(err)
        })
    },
    async importCsv() {
      if (this.hasGetNextCode) {
        const props = {
          message: 'コードを自動で付与します。よろしいですか？',
          confirmShowDialog: true,
          localStorageSetName: 'confirmMasterCodeAutomatically',
        }
        const yes = await this.$confirm(props)
        if (!yes) {
          return
        }

        let nextAccountCode = 1001
        const existAccountList = [] // [account_name:{code: '1001', count: 1}, ...]
        for (const data of this.import_data.items) {
          if (!existAccountList[data.account_name]) {
            data.account_code = String(nextAccountCode)

            existAccountList[data.account_name] = {}
            existAccountList[data.account_name].code = String(nextAccountCode)
            existAccountList[data.account_name].count = 1
            nextAccountCode += 1
          } else {
            data.account_code = existAccountList[data.account_name].code
            existAccountList[data.account_name].count += 1
          }
          if (data.sub_name) {
            const subCode = existAccountList[data.account_name].count
            data.sub_code = String(subCode)
          }
        }
      }
      if (this.dbDuplicate.count > 0) {
        const props = {
          message: 'コードが重複するデータは上書きされます。よろしいですか？',
          confirmShowDialog: true,
          localStorageSetName: 'confirmMasterOverwrite',
        }
        const yes = await this.$confirm(props)
        if (!yes) {
          return
        }
      }
      try {
        var data = this.import_data

        this.$refs.progressDialog.open(
          this.$t('message.progress_import_naster')
        )

        this.$store.dispatch('importMasterData', { data }).then((response) => {
          this.$refs.progressDialog.close()

          if (response.data.status == 'success') {
            Snackbar.success(this.$t('message.success_import_csv'))

            this.data = response.data.account_data
            this.getJournalItems()
          } else {
            Snackbar.error(response.data.error)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    resetForm() {
      try {
        this.$refs.dialogAdd.resetValidation()
        this.$refs.dialogEdit.resetValidation()
        initInputsObj(this.inputs)
        this.inputs.gensenzei_account = false
      } catch (e) {
        console.log(e)
      }
    },
    getIndex(index) {
      try {
        return (
          this.pagination.page * this.pagination.rowsPerPage -
          this.pagination.rowsPerPage +
          index
        )
      } catch (e) {
        console.log(e)
      }
    },
    addSuppSubject() {
      this.selectedAccountSubjects.push({ name: '', code: '', status: 1 })
    },
    async deleteSuppSubject(index) {
      const subject = this.selectedAccountSubjects[index]
      if (subject.id) {
        const yes = await this.$confirm.deleteWithInput({
          message: 'この補助科目を削除します。続行しますか？',
        })
        if (!yes) {
          return
        }
      }
      this.selectedAccountSubjects.splice(index, 1)
    },
    showSuppSubjects(data) {
      try {
        data.addcode = ''
        data.addsubject = ''
        data.addstatus = 1
        if (data.showsubjects == 0) {
          data.showsubjects = 1
        } else {
          data.showsubjects = 0
        }
      } catch (e) {
        console.log(e)
      }
    },
    getItemIndex(id) {
      try {
        return this.data.findIndex((x) => x.id == id)
      } catch (e) {
        console.log(e)
      }
    },
    checkDuplicateCode(code) {
      let count = 0
      for (const subject of this.selectedAccountSubjects) {
        if (subject.code === code) {
          count += 1
          if (count > 1) {
            return false
          }
        }
      }
      return true
    },
    submitEditAccount() {
      try {
        if (this.$refs.dialogEdit.validate()) {
          const auth_token = this.$store.getters.getAuthToken
          const apiUrl = this.$store.getters.apiAccountTitleUrl
          const data = {
            id: this.selectedAccount.id,
            name: this.inputs.name,
            user_organization_id: this.$store.getters.getUserOrganizationID,
            organization_id: this.$store.getters.getOrganizationID,
            auth_token: auth_token,
            tax_classification_id: this.inputs.taxId || null,
            gensenzei_account: this.inputs.gensenzei_account,
            code: this.inputs.code,
            status: this.inputs.status,
            settings_subaccount: this.selected.settings_subaccount,
            settings_department: this.selected.settings_department,
            settings_vendor: this.selected.settings_vendor,
            settings_project: this.selected.settings_project,
            settings_segment: this.selected.settings_segment,
            settings_item: this.selected.settings_item,
            settings_tag: this.selected.settings_tag,
            settings_walletable: this.selected.settings_walletable,
            settings_tax: this.selected.settings_tax,
            subjects: this.selectedAccountSubjects,
          }
          this.$store
            .dispatch('editAccount', {
              data,
              apiUrl,
            })
            .then((response) => {
              if (response.data.status == 'success') {
                this.data[this.selected_account_index].name = this.inputs.name
                this.data[this.selected_account_index].tax = this.inputs.taxId
                let taxObj = this.tax_list.find(
                  (x) => x.id == this.inputs.taxId
                )
                this.data[this.selected_account_index].tax_name = taxObj
                  ? taxObj.name
                  : ''
                this.data[this.selected_account_index].code = this.inputs.code
                this.data[this.selected_account_index].status =
                  this.inputs.status
                this.data[this.selected_account_index].gensenzei_account =
                  this.inputs.gensenzei_account
                this.data[this.selected_account_index].settings_subaccount =
                  this.selected.settings_subaccount
                this.data[this.selected_account_index].settings_department =
                  this.selected.settings_department
                this.data[this.selected_account_index].settings_vendor =
                  this.selected.settings_vendor
                this.data[this.selected_account_index].settings_project =
                  this.selected.settings_project
                this.data[this.selected_account_index].settings_segment =
                  this.selected.settings_segment
                this.data[this.selected_account_index].settings_item =
                  this.selected.settings_item
                this.data[this.selected_account_index].settings_tag =
                  this.selected.settings_tag
                this.data[this.selected_account_index].settings_walletable =
                  this.selected.settings_walletable
                this.data[this.selected_account_index].settings_tax =
                  this.selected.settings_tax
                this.data[this.selected_account_index].subjects =
                  response.data.subjects
                this.$refs.dialogEdit.close()
                Snackbar.success(this.$t('message.success'))
                this.getJournalItems()
              }
            })
        }
      } catch (e) {
        console.log(e)
      }
    },
    deleteAccount() {
      try {
        const auth_token = this.$store.getters.getAuthToken
        const apiUrl = this.$store.getters.apiAccountTitleUrl
        const data = {
          id: this.selectedAccount.id,
          auth_token: auth_token,
        }
        this.$store
          .dispatch('deleteAccount', {
            data,
            apiUrl,
          })
          .then((response) => {
            if (response.data.status == 'success') {
              this.data.splice(this.selected_account_index, 1)
              Snackbar.success(this.$t('message.success'))
              this.getJournalItems()
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    submitBulkEdit() {
      try {
        const ids = this.selectedIds
        const status = this.bulkEditInputs.status
        if (ids.length > 0) {
          Axios.put(
            this.$store.getters.apiAccountTitlesUrl,
            {
              ids: ids,
              status: status,
              organization_id: this.$store.getters.getOrganizationID,
              user_organization_id: this.$store.getters.getUserOrganizationID,
            },
            { headers: { Authorization: this.$store.getters.getAuthToken } }
          ).then((response) => {
            if ('error' in response.data) {
              Snackbar.error(response.data.error)
            } else {
              updateTableDataStatus(this.data, ids, status)
              this.$refs.dialogBulkEdit.close()
              Snackbar.success(this.$t('message.success'))
              this.getJournalItems()
            }
          })
        } else {
          Snackbar.error(this.$t('message.no_selected_data'))
        }
      } catch (err) {
        console.log(err)
      }
    },
    submitBulkDelete() {
      try {
        const ids = this.selectedIds
        if (ids.length > 0) {
          Axios.delete(this.$store.getters.apiAccountTitlesUrl, {
            data: { ids: ids },
            headers: { Authorization: this.$store.getters.getAuthToken },
          }).then((response) => {
            if ('error' in response.data) {
              Snackbar.error(response.data.error)
            } else {
              deleteTableData(this.data, ids, status)
              this.$refs.dialogBulkDelete.close()
              this.resetSelected()
              Snackbar.success(this.$t('message.success'))
              this.getJournalItems()
            }
          })
        } else {
          Snackbar.error(this.$t('message.no_selected_data'))
        }
      } catch (err) {
        console.log(err)
      }
    },
    selectAccount(data) {
      try {
        this.selected = Object.assign({}, data)
        this.selectedAccount = Object.assign({}, data)
        this.selected_account_index = this.getItemIndex(data.id)
        this.inputs.name = data.name
        this.inputs.taxId = data.tax
        this.inputs.gensenzei_account = data.gensenzei_account
        this.inputs.code = data.code
        this.inputs.status = data.status
        this.selectedAccountSubjects = []
        for (const subject of data.subjects) {
          const instance = {}
          instance.id = subject.id
          instance.name = subject.name
          instance.code = subject.code
          instance.status = subject.status
          this.selectedAccountSubjects.push(instance)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async addInstance() {
      this.loader = false
      const apiUrl = this.$store.getters.apiAccountTitleUrl
      var tax_class_id = ''
      if (this.inputs.taxId) {
        tax_class_id = this.inputs.taxId
      }
      const data = {
        user_organization_id: this.$store.getters.getUserOrganizationID,
        organization_id: this.$store.getters.getOrganizationID,
        name: this.inputs.name,
        tax_classification_id: tax_class_id,
        auth_token: this.$store.getters.getAuthToken,
        code: this.inputs.code,
        status: this.inputs.status,
        gensenzei_account: this.inputs.gensenzei_account,
      }
      this.$store
        .dispatch('addAccount', {
          data,
          apiUrl,
        })
        .then((response) => {
          if (response.data.status == 'success') {
            const taxObj = this.tax_list.find(
              (x) => x.id == response.data.data.tax_classification_id
            )
            const tax_id = taxObj ? taxObj.id : ''
            const tax_class_name = taxObj ? taxObj.name : ''
            const toAdd = {
              id: response.data.data.id,
              sort_num: response.data.data.sort_num,
              name: response.data.data.name,
              tax_name: tax_class_name,
              tax: tax_id,
              showsubjects: 1,
              subjects: [],
              gensenzei_account: this.inputs.gensenzei_account,
              code: this.inputs.code,
              status: this.inputs.status,
              settings_subaccount: -1,
              settings_department: -1,
              settings_project: -1,
              settings_segment: -1,
              settings_vendor: -1,
              settings_item: -1,
              settings_tag: -1,
              settings_walletable: -1,
              settings_tax: -1,
              settings_free_text_1: -1,
              settings_free_text_2: -1,
            }

            this.$refs.dialogAdd.close()
            this.data.push(toAdd)
            this.resetForm()
            Snackbar.success(this.$t('message.success'))
            this.getJournalItems()
          }
        })
    },
    checkAdd() {
      try {
        if (this.$refs.dialogAdd.validate()) {
          const data = {
            data: this.data,
            item: {
              name: this.inputs.name,
              code: this.inputs.code,
              status: this.inputs.status,
            },
            keys: ['name', 'code'],
          }
          this.$store.dispatch('checkDuplicates', data).then((result) => {
            if (result.isDuplicate === true) {
              this.$refs.dialogConfirmAdd.set(result.duplicateItems)
              this.$refs.dialogConfirmAdd.open()
            } else {
              this.addInstance()
            }
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    getCurrentAppLayoutHandler() {
      return getCurrentAppLayout(this.$router)
    },
    getDisplaySetting() {
      try {
        setDisplaySetting(this.displaySetting, this.organization)
      } catch (e) {
        console.log(e)
      }
    },
    mutateSubAccount(accountTitle) {
      //余分なkeyを与えず、storeのaccounttitleを変更する
      const required = [
        'id',
        'code',
        'company_account_title_id',
        'name',
        'name_code',
        'sort_num',
        'status',
      ]
      const copyAccountTitle = deepCopy(accountTitle)
      copyAccountTitle.subjects.forEach((subAccount) => {
        Object.keys(subAccount).forEach((key) => {
          if (required.indexOf(key) === -1) {
            delete subAccount[key]
          }
        })
      })
      const propertyName = 'accountTitleList'
      const payload = { propertyName, masterData: copyAccountTitle }
      this.$store.commit('setMasterData', payload)
    },
    onPagenation(pageInfo) {
      this.itemsLength = pageInfo.itemsLength
      this.pageInfo.start = pageInfo.pageStart + 1
      this.pageInfo.end = pageInfo.pageStop
      scrollTableToTop('account-title-table')
    },
    toggleOne(item) {
      try {
        if (!item.selected) {
          this.$set(item, 'selected', true)
        } else {
          this.$set(item, 'selected', false)
        }
      } catch (e) {
        console.log(e)
      }
    },
    onClickSelectAll(selectType, message) {
      //selectType  1:全選択 2:現在ページ選択 3:全解除 4:現在ページ解除
      switch (selectType) {
        case 1: {
          for (let item of this.data) {
            this.$set(item, 'selected', true)
          }
          break
        }
        case 2: {
          for (let item of this.filteredData) {
            this.$set(item, 'selected', true)
          }
          break
        }
        case 3: {
          for (let item of this.data) {
            this.$set(item, 'selected', false)
          }
          break
        }
        case 4: {
          for (let item of this.filteredData) {
            this.$set(item, 'selected', false)
          }
          break
        }
      }
      Snackbar.success(message)
    },
    resetSelected() {
      for (let item of this.data) {
        this.$set(item, 'selected', false)
      }
      this.selectedIds = []
    },
    getfilteredData(event) {
      this.filteredData = event
    },
    getSelectedIds() {
      try {
        this.selectedIds = this.data
          .filter((item) => item.selected)
          .map((item) => item.id)
      } catch (e) {
        this.selectedIds = []
        console.log(e)
      }
    },
    clickBulkButton(type) {
      this.getSelectedIds()
      if (this.selectedIds.length > 0) {
        switch (type) {
          case 'edit':
            this.$refs.dialogBulkEdit.open()
            break
          case 'delete':
            this.$refs.dialogBulkDelete.open()
            break
        }
      } else {
        Snackbar.error(this.$t('message.no_selected_data'))
      }
    },
    openAccountTitleSortNum(item) {
      try {
        this.selectedAccount = item
        this.editingAccountTitleSortNum = String(item.sort_num)
        this.$refs.dialogEditAccountTitleSortNum.open()
      } catch (e) {
        console.log(e)
      }
    },
    saveAccountTitleSortNum() {
      try {
        let item = this.selectedAccount
        item.sort_num = this.editingAccountTitleSortNum
        this.updateSort('one')
        this.$refs.dialogEditAccountTitleSortNum.close()
      } catch (e) {
        console.log(e)
      }
    },
    openSubjectSortNum(item) {
      try {
        this.selectedSupplementary = item
        this.editingSubjectSortNum = String(item.sort_num)
        this.$refs.dialogEditSubjectSortNum.open()
      } catch (e) {
        console.log(e)
      }
    },
    saveSubjectSortNum() {
      try {
        let subItem = this.selectedSupplementary
        subItem.sort_num = this.editingSubjectSortNum
        this.updateSort('one')
        this.$refs.dialogEditSubjectSortNum.close()
      } catch (e) {
        console.log(e)
      }
    },
    onClickShowAllSubjects() {
      this.showAllSubjects = !this.showAllSubjects
      for (const item of this.data) {
        item.showsubjects = this.showAllSubjects
      }
    },
  },
}
</script>
